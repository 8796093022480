<template>
  <v-container fluid>
    <v-row class="text-h3 mt-16" justify="center">
      <v-col cols="12" class="text-center"
        >Добро пожаловать в систему регистрации билетов</v-col
      >
      <v-col cols="12" class="text-center text-h2 font-weight-medium mt-10">
        <span class="orange--text">Bpo</span>Booking
      </v-col>
    </v-row>
    <!-- <v-row class="text-h1 mt-16" justify="center">
      <v-btn to="/find-tkt" dark color="orange" elevation="0" class="mr-5">Найти рейс</v-btn>
      <v-btn to="/passengers" dark color="orange" elevation="0">Список пассажиров</v-btn>
    </v-row> -->
  </v-container>
</template>

<script>
export default {
  name: "Index",
};
</script>

<style scoped></style>
